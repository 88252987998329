
import { defineComponent } from 'vue';
import { footerWidthFluid } from '@/core/helpers/config';
import MainLogo from '@/layout/header/partials/MainLogo.vue';
export default defineComponent({
  name: 'KTFooter',
  components: {
    MainLogo,
  },
  setup() {
    return {
      footerWidthFluid,
    };
  },
});
